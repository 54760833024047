import sendRequest from "../API";
import API_Routes from "../API/API_Routes";

const createCommande = async (commande, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.createCommande,
      "POST",
      commande,
      signal
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCommande = async (id, data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.updateCommande + id,
      "PUT",
      data,
      signal
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getCommandeByConfigToken = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.getCommandeByConfigToken,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export { createCommande, getCommandeByConfigToken, updateCommande };
