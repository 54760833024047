import { useLocalStorage } from "primereact/hooks";
import { useState, useRef } from 'react';
import { pays, typeNumber, zones } from "../../static";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { numberChooseSchema } from "../../schema/numberChooseSchema";
import ControlledInput from "../inputs/ControlledInput";
import InputContainer from "../containers/InputContainer";
import Numbers from "./Numbers";
import { Fieldset } from 'primereact/fieldset';
import EditNumber from "./EditNumber";
import Steps from "./Steps";
import {
  countryOptionTemplate,
  optionTemplate,
  selectedCountryTemplate,
  valueTemplate,
} from "../inputs/Templates";
import { onNumerChoose } from "../../querys/inscription";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import routes from "../../router/routes";
import MainButton from "./MainButton";
import { Tooltip } from "primereact/tooltip";

const NumberChoose = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const location = useLocation();
  const [, setChoixNumero] = useLocalStorage({}, "choixNumero");
  const navigate = useNavigate();
  const methods = useForm({
    values: {
      pays: "France",
      numeroType: "or",
    },
    resolver: zodResolver(numberChooseSchema),
  });
  const paysValue = useWatch({
    control: methods.control,
    name: "pays",
  });
  const zoneValue = useWatch({
    control: methods.control,
    name: "zone",
  });
  const numeroTypeValue = useWatch({
    control: methods.control,
    name: "numeroType",
  });

  const selectedNumberValue = useWatch({
    control: methods.control,
    name: "number",
  });

  const onSubmit = (data) => {
    const cleanData = onNumerChoose(data);
    setChoixNumero(JSON.stringify(cleanData));
    navigate(routes.informationEntreprise + location.search);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsTextVisible(!isTextVisible);
  };


  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="px-4 lg:px-8 pb-8"
      >
        <Steps step={1} maxStep={3} />
        <h5 className="flex w-12">
          <a
            href="#"
            className="conserver-num text-black-alpha-90 underline font-medium text-sm sm:text-sx py-2"
            onClick={handleClick}
          >
            Vous souhaitez conserver votre numéro historique ?
          </a>
        </h5>
        {isTextVisible && (
          <div style={{width:'700px'}} className="mt-2 text-justify">
            <p>
              Si vous souhaitez utiliser un numéro déjà existant avec Standard en ligne, c’est facile et sans coupure !
              Choisissez un numéro de votre choix, et une fois votre commande validée, envoyez un email à support@standardenligne.fr.
              Nous effectuerons la portabilité de votre numéro historique sans coupure ni interruption de service afin que vous puissiez utiliser Standard en ligne avec le même numéro de téléphone.
            </p>
          </div>
        )}
        <InputContainer
          show={true}
          label="Dans quel pays souhaitez-vous obtenir un numéro de téléphone ?"
        >
          <ControlledInput name="pays" control={methods.control}>
            <Dropdown
              className="w-full h-3rem align-items-center border-round border-1 text-black-alpha-90 font-bold bg-white pl-3 md:p-4"
              placeholder="Choisissez le pays"
              options={pays}
              optionLabel="label"
              optionValue="value"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
            />
          </ControlledInput>
        </InputContainer>
        {paysValue !== "France" ? (
          <p style={{ backgroundColor: '#16C498', width: '605px' }} className="mt-6 text-white p-3 border-round">
            Pour obtenir un numéro dans ce pays, contactez-nous au 01 87 200 200
          </p>
        ) : null}
        <InputContainer
          show={paysValue === "France"}
          label="Dans quelle zone ?"
        >
          <ControlledInput name="zone" control={methods.control}>
            <Dropdown
              className="w-full h-3rem align-items-center border-round border-1 text-black-alpha-90 font-bold bg-white pl-3 md:p-4"
              placeholder="Choisissez la zone"
              options={zones}
              optionLabel="label"
              optionValue="value"
              valueTemplate={valueTemplate}
            />
          </ControlledInput>
        </InputContainer>

        <InputContainer show={zoneValue} label="Choisissez votre numéro">
          <ControlledInput name="numeroType" control={methods.control}>
            <Dropdown
              className="w-full h-3rem align-items-center border-round border-1 text-black-alpha-90 font-bold bg-white pl-3 md:p-4"
              placeholder="Choisissez votre numéro"
              options={typeNumber}
              optionLabel="label"
              optionValue="value"
              valueTemplate={optionTemplate}
              itemTemplate={optionTemplate}
            />
          </ControlledInput>
        </InputContainer>

        {numeroTypeValue !== "editNumber" ? (
          <InputContainer show={zoneValue}>
            <ControlledInput name="number" control={methods.control}>
              <Numbers
                selectedNumber={selectedNumberValue}
                numberZone={zoneValue}
                numberType={numeroTypeValue}
              />
            </ControlledInput>
          </InputContainer>
        ) : (
          <InputContainer show={zoneValue}>
            <ControlledInput name="number" control={methods.control}>
              <EditNumber numberZone={zoneValue} />
            </ControlledInput>
          </InputContainer>
        )}

        {selectedNumberValue ? (
          <div className="flex relative w-12 lg:w-8 align-items-center justify-content-center lg:justify-content-end mt-4 mb-3">
            <MainButton />
          </div>
        ) : null}
      </form>
      <Fieldset className="condition-fieldset" collapsed={true} legend="*Conditions liées aux appels sortants" toggleable>
        <p style={{fontSize:'12px'}} className="m-0 text-justify">
          <b>Licence START :</b> Les appels sortants sont inclus vers les Fixes et Mobiles en France Métropolitaine et Corse vers 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel. Au-delà, facturation hors forfait au tarif de 0,15€/min HT pour la France Métropolitaine et Corse, et 0,89€/min HT pour les autres destinations.<br />
          <b>Licence PRO :</b>Les appels sortants sont inclus vers les Fixes et Mobiles en France Métropolitaine, Corse, DOM et destinations internationales présentes dans les conditions générales d’utilisations et dans la page de comparaison des offres sont inclus vers 100 correspondants différents maximum par mois par utilisateur et 1h maximum par appel. Au-delà, facturation hors forfait au tarif de 0,15€/min HT pour la France Métropolitaine et Corse, et 0,89€/min HT pour les autres destinations.
        </p>
      </Fieldset>
    </FormProvider>
  );
};

export default NumberChoose;
