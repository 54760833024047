import React from "react";
import Logo from "../assets/images/logo/newLogo.svg";
import "../assets/css/Header.css";

function Header() {
  return (
    <>
      <header className="header-container">
        <img src={Logo} alt="standard en ligne logo" />
      </header>
    </>
  );
}

export default Header;
