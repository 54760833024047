const routes = {
  inscription: "/",
  choixNumero: "/",
  informationEntreprise: "/information-entreprise",
  inscriptionPayment: "/paiement",
  inscriptionSuccess: "/success",
  successCheckout: "/success-checkout",
  success: "/success",
  cancel: "/cancel",
  cancelCommande: "/annuler-commande",
  error: "/error",
  oops: "/oops",
  configInfos: "/config/step1",
  configRenvoi: "/config/renvoi",
  configMandat: "/config/mandat",
  configMenu: "/config/menu",
  configChoix: "/config/choix",
  configFinish: "/config/finish",
  404: "*",
};

export default routes;
