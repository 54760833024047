import { cancelStripeSubscription } from "../services/stripe";

const onCancelSubscription = async (data, signal = null) => {
  const filtredData = {
    commande: data.commande,
    email: data.email,
    telephone: data.telephone.replaceAll(" ", ""),
  };

  return await cancelStripeSubscription(filtredData, signal);
};

export { onCancelSubscription };